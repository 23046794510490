@import '../../../styles/core.css';

.root {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 0;
  padding: 0;
  padding-left: 14px;
  list-style: none;

  & :global {
    li {
      position: relative;
    }

    li::before {
      content: '';
      position: absolute;
      top: 12px;
      left: -14px;
      display: inline-block;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: var(--priority-primary-80);
      transform: translateY(-50%);
    }
  }
}

@media (--screen-lg) {
  .root {
    gap: 16px;
  }
}
