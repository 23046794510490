@import '../../../../shared/styles/core.css';

.title {
  display: flex;
  gap: 4px;
  justify-content: flex-start;
  align-items: flex-start;
  width: fit-content;
  min-height: unset;
  padding: 0;
  color: var(--priority-primary-80, #2d3fc0);
}

.inner {
  position: relative;
  z-index: 10;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  box-sizing: border-box;
  width: 100%;

  & .title {
    background-color: transparent;
  }
}

.container {
  display: contents;
}

.root {
  all: unset;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: hidden;
  box-sizing: border-box;
  width: 100%;
  min-height: 204px;
  padding: 24px 16px;
  background-color: var(--priority-primary-20, #eef0fb);
  background-position: calc(100% + 72px) calc(100% - 7px);
  background-size: 228px 125px;
  background-repeat: no-repeat;

  & .title {
    &:hover,
    &:focus {
      background-color: transparent;
    }
  }
}

.mobileBg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 204px;
  background-color: transparent;
  background-position: calc(100% + 105px) calc(100% - 7px);
  background-size: 228px 125px;
  background-repeat: no-repeat;
  opacity: 0.5;
}

.loader {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: hidden;
  box-sizing: border-box;
  width: 100%;
  min-height: 204px;
  background-color: var(--priority-primary-20, #eef0fb);
}

.text {
  width: 63%;
}

.titleLabel {
  order: 1;
  color: var(--priority-primary-80, #2d3fc0);
}

.icon {
  flex-shrink: 0;
  order: 2;
  width: 32px;
  height: 32px;
  transform: rotate(-90deg);
}

.illustration {
  position: absolute;
  right: -72px;
  bottom: 7px;
  display: flex;
  width: 228px;
  height: 125px;

  & img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
}

@media (--screen-xs) {
  .root {
    background-position: calc(100% + 105px - 5vw) 70%;
  }
}

@media (--screen-sm) {
  .root {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-height: 164px;
    padding: 24px;
    background-position: calc(100% + 120px) 29px;
    background-size: 226px 106px;
  }

  .inner {
    max-width: 390px;
  }

  .illustration {
    position: relative;
    right: unset;
    bottom: unset;
    width: 226px;
    height: 106px;
  }

  .title {
    align-items: center;
  }

  .text {
    width: 100%;
  }

  .icon {
    width: 28px;
    height: 28px;
  }
}

@media (min-width: 600px) {
  .root {
    background-position: calc(100% + 85px) 29px;
  }
}

@media (min-width: 630px) {
  .root {
    background-position: calc(100% + 45px) 29px;
  }
}

@media (min-width: 720px) {
  .root {
    background-position: calc(100% - 24px) 29px;
  }
}

@media (--screen-lg) {
  .root {
    background-position: calc(100% + 150px) 30px;
    background-size: 256px 132px;
  }

  .illustration {
    width: 256px;
    height: 132px;
    margin-right: -10px;
  }

  .loader {
    height: 100%;
    min-height: unset;
  }

  .inner {
    gap: 8px;
    width: 84%;
  }

  .title {
    gap: 0;
  }

  .text {
    width: 100%;
  }

  .icon {
    width: 32px;
    height: 32px;
  }
}

@media (min-width: 1120px) {
  .root {
    background-position: calc(100% + 85px) 30px;
  }
}

@media (min-width: 1180px) {
  .root {
    background-position: calc(100% + 45px) 40px;
  }

  .inner {
    gap: 8px;
    width: auto;
    max-width: 390px;
  }
}

@media (min-width: 1240px) {
  .root {
    background-position: calc(100% - 14px) 28px;
  }
}

@media (--screen-xl) {
  .root {
    min-height: 150px;
    background-position: top 19px right 10%;
    background-size: auto;
  }

  .title {
    gap: 4px;
  }

  .illustration {
    width: 229px;
    height: 115px;
    margin-right: 78px;
  }

  .inner {
    gap: 4px;
    width: 64%;
    max-width: 567px;
  }
}
