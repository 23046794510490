@import '../../../../shared/styles/core.css';

.root {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 80px 0 92px;
}

.toolbar {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 80px;
  padding: 16px 0;
  background-color: var(--default-white-100);
  box-shadow: 0 2px 10px 0 #0000001F;

  &.mobile {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    left: 0;
    z-index: 100;
    padding: 32px 16px 0;

    @media (--s-xl) {
      display: none;
    }

    @media (--s-md) {
      padding: 32px 32px 0;
    }

    @media (--s-xl) {
      padding: 32px 24px 0;
    }

    @media (--s-3xl) {
      padding: 32px 24px 0;
    }
  }

  &.desktop {
    position: absolute;
    right: 0;
    top: 0;
    left: 0;
    z-index: 100;
    display: none;

    @media (--s-xl) {
      display: flex;
    }

    @media (--s-md) {
      padding: 32px 16px 0;
    }

    @media (--s-xl) {
      padding: 32px 24px 0;
    }

    @media (--s-3xl) {
      padding: 32px 32px 0;
    }
  }
}

.content {
  position: relative;
  overflow-y: auto;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 16px 0;

  &.mobile {
    display: block;
    padding: 0 16px 128px;

    @media (--s-md) {
      padding: 0 32px 128px;
    }

    @media (--s-xl) {
      padding: 0 32px 128px;
    }

    @media (--s-3xl) {
      padding: 0 24px 128px;
    }

    @media (--s-xl) {
      display: none;
    }
  }

  &.desktop {
    display: none;

    @media (--s-xl) {
      display: block;
    }

    @media (--s-md) {
      padding: 0 16px 128px;
    }

    @media (--s-xl) {
      padding: 0 24px 128px;
    }

    @media (--s-3xl) {
      padding: 0 32px 128px;
    }
  }

  & :global {
    .accordion__item {
      @media (--s-md) {
        border-top: none;
      }
    }
  }
}

@media (--s-xl) {
  .content {
    & :global {
      .accordion__item {
        border-top: 1px solid var(--default-grey-30);
      }
    }
  }
}

.icon {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid var(--default-grey-40);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;
}

.actions {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  display: flex;
  align-items: flex-start;
  box-sizing: border-box;
  width: 100%;
  height: 92px;
  padding: 16px 16px 32px;
  background-color: var(--default-white-100);
  box-shadow: 0 0 12px 0 #0000001F;

  @media (--s-md) {
    padding: 16px 32px 32px;
  }

  @media (--s-3xl) {
    padding: 16px 32px 32px;
  }
}

.count {
  display: flex;
  gap: 8px;
  margin-left: auto;
  color: inherit;
}

.submit {
  min-height: auto;
  white-space: pre-wrap;
}

.resetLink {
  margin-left: auto;

}

.filter {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;

  @media (--s-3xl) {
    gap: 14px;
  }

  .filterItem {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    width: 100%;
  }

  .field {
    width: 100%;

    &.halfWidth {
      width: calc(50% - 8px);
    }

    .numberControl {
      margin: 2px 0 16px;
      padding: 12px;
      border: 1px solid #D6D6D6;
      border-radius: 4px;
      width: 100%;
    }
  }
}

.accordion {
  :global(.accordion__item) {
    border-top: 1px solid transparent;
    border-bottom: 1px solid #F0F0F0;
  }
}

.navigations {
  padding: 16px 0 0;

  @media (--s-md) {
    padding: 16px 0 0;
  }

  @media (--s-xl) {
    padding: 0;
  }

  .navigation {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 12px 0 11px;
    border: none;
    border-bottom: 1px solid #F0F0F0;
    background: none;
    outline: none;
    cursor: pointer;

    @media (--s-3xl) {
      padding: 19px 0 18px;
    }
  }
}

.rangeFilter {
  width: 100%;
  margin: 0 0 16px;
  padding: 0 20px 0 0;
}
