@import '../../../../shared/styles/core.css';

.root {
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  align-items: center;
}

.title {
  margin-right: 16px;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;

  @media (--s-xl) {
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
  }

  @media (--s-3xl) {
    font-size: 18px;
    font-weight: 600;
    line-height: 26px;
  }
}

.count {
  margin-left: auto;
}

.button {
  display: inline-flex;


  @media (--s-xl) {
    display: none;
  }
}

.wrapper {
  display: flex;
  gap: 12px;
  justify-content: flex-start;
  align-items: flex-start;
  box-sizing: border-box;
  width: 100%;
  height: 54px;
  margin: 0;
  color: var(--default-grey-70);
}
