.root {
  overflow: visible;
  width: 70px;
  cursor: pointer;
}

.root .ring {
  transform-origin: center center;
  animation: starting-fill 0.5s forwards, vary-loader-width 3s 0.5s linear infinite alternate,
    spin 1.6s 0.2s linear infinite;
}

.root .overlay {
  visibility: hidden;
  transform-origin: center center;
  animation: spin 1.6s 0.2s linear infinite;
}

.complete .overlay {
  visibility: visible;
  animation: complete-fill 0.5s linear forwards, spin 1.6s 0.2s linear infinite;
}

.complete .ring {
  animation: starting-fill 0.5s forwards, vary-loader-width 3s 0.5s linear infinite alternate,
    spin 1.6s 0.2s linear infinite, fade 0.1 0.5s linear forwards;
}

.complete .root {
  cursor: initial;
  pointer-events: none;
  transition: all 0s 0.9s;
  animation: fade 0.2s 0.7s linear forwards;
}

@keyframes starting-fill {
  to {
    stroke-dashoffset: 270;
  }
}

@keyframes vary-loader-width {
  0% {
    stroke-dashoffset: 270;
  }

  50% {
    stroke-dashoffset: 170;
  }

  100% {
    stroke-dashoffset: 275;
  }
}

@keyframes complete-fill {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes fade {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
