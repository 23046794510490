@import '../../../../shared/styles/core.css';

.root {
  padding: 0;
}

.title {
  margin-bottom: 16px;
}

.item {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 24px;
  }
}

.wrapper {
  display: grid;
  grid-template-rows: auto;
  grid-auto-rows: auto;
  width: 100%;
  padding: 0;

  &.clamped {
    display: grid;
    grid-template-rows: repeat(3, auto);
    grid-auto-rows: 0;

    & .item {
      &:not(:last-child) {
        margin-bottom: 0;
      }

      &:first-of-type {
        margin-bottom: 10px;
      }
    }
  }
}

.list {
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
}

.limiterControl {
  font-weight: 700;
}

.text {
  margin: 0;
}

@media (--screen-lg) {
  .root {
    max-width: 681px;
  }

  .anchor {
    content: '';
    position: absolute;
    top: -262px;
    left: 0;
    display: inline-block;
  }
}

@media (--screen-xl) {
  .root {
    max-width: 1005px;
  }
}
