@import '../../../../shared/styles/core.css';

.root {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-row-gap: 24px;
  grid-column-gap: 16px;
  box-sizing: border-box;
  width: 100%;
  margin: 16px auto 0;
  padding: 0;
  list-style: none;
}

.item {
  scroll-padding-top: 170px;
}

.expanded {
  & :global {
    .catalog-card__wrapper {
      z-index: 20;
    }
  }
}

@media (--s-md) {
  .root {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-row-gap: 24px;
    grid-column-gap: 16px;
    padding: 0;
  }
}

@media (--s-xl) {
  .root {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-row-gap: 48px;
    grid-column-gap: 24px;
    margin: 24px auto 0;
    padding: 0;
  }

  .item {
    overflow: visible;
  }
}

@media (--s-3xl) {
  .root {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-row-gap: 40px;
  }

  .item {
  }
}
