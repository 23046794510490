@import '../../shared/styles/core.css';

.root {
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--priority-secondary-100);
  color: var(--default-white-100);
  box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.2);
  will-change: box-shadow;

  &:hover,
  &:focus-visible,
  &:active {
    box-shadow: none;
  }
}

.wrapper {
  position: fixed;
  right: 16px;
  bottom: 110px;
  z-index: 10000;
  display: flex;
}

.enter {
  opacity: 0;
}

.leave {
  opacity: 1;
}

.hide {
  animation: show var(--transition-md) reverse forwards;
}

.show {
  animation: show var(--transition-md) forwards;
}

.icon {
  transform: rotate(180deg);
}

@media (min-width: 1280px) {
  .wrapper {
    right: 20px;
    bottom: 96px;
  }

  .root {
    width: 60px;
    height: 60px;
  }
}

@keyframes show {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
