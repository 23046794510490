@import '../../../../shared/styles/core.css';

.root {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: flex-start;
  max-width: 681px;
}

.content {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: flex-start;
}

.block {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  margin-bottom: 32px;
  color: var(--default-grey-100, #212121);

  &:last-child {
    margin-bottom: 14px;
  }
}

.video {
  display: flex;
  width: 100%;
  max-width: 343px;
  height: 196px;
}

.link {
  display: inline-block;
}

.gallery {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
}

.picture {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  max-width: 343px;
  height: 242px;
  border: 1px solid var(--default-grey-30, #d6d6d6);

  & :global {
    & picture {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
    }

    .picture__img {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }
}

.textContent {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}

@media (--screen-lg) {
  .video {
    max-width: 329px;
    height: 189px;
    margin-top: -8px;
  }

  .gallery {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 24px;
  }

  .block {
    &:last-child {
      margin-bottom: 8px;
    }
  }

  .picture {
    max-width: unset;
    height: 232px;
  }

  .textContent {
    gap: 8px;
  }
}

@media (--screen-xl) {
  .root {
    max-width: unset;
  }

  .picture {
    height: 346px;
  }

  .video {
    max-width: 491px;
    height: 283px;
    margin: 0;
  }
}
