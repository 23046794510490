/* stylelint-disable no-descending-specificity */
@import '../../../styles/core.css';

.radio {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  align-items: center;
  border-radius: 4px;
  color: var(--default-grey-70, #717070);
  cursor: pointer;

  &:focus-visible {
    outline: 2px solid var(--priority-primary-80, #2d3fc0);
  }

  &:not(.disabled) {
    &:hover,
    &:focus-visible {
      & .icon {
        border: 1px solid var(--default-grey-70, #717070);
      }

      & :global(.radio__icon) {
        border: 1px solid var(--default-grey-70, #717070);
      }
    }
  }
}

.icon {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 24px;
  height: 24px;
  border: 1px solid var(--default-grey-40, #bdbdbd);
  border-radius: 50%;
  background: var(--default-white-100, #ffffff);
}

.icon.checked {
  border: 1px solid var(--priority-primary-60, #9ca5e8);
  background: var(--default-white-100, #ffffff);

  &::after {
    content: '';
    position: absolute;
    flex-shrink: 0;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: var(--priority-primary-80, #2d3fc0);
  }
}

.disabled {
  color: var(--default-grey-40, #bdbdbd);
  cursor: not-allowed;

  & .icon {
    border: 1px solid var(--default-grey-40, #bdbdbd);
    background: var(--default-grey-20, #f0f0f0);
  }
}

.icon.disabled {
  border: 1px solid var(--default-grey-40, #bdbdbd);
  background: var(--default-grey-20, #f0f0f0);
}

.icon.checked.disabled {
  &::after {
    background: var(--default-grey-40, #bdbdbd);
  }
}

.icon.error {
  border: 1px solid var(--status-error-40, #e99696);
  background: var(--default-grey-20, #f0f0f0);
}

.input {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;

  &:checked + .radioIcon {
    background: var(--default-white-100, #ffffff);

    &::after {
      content: '';
      position: absolute;
      flex-shrink: 0;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: var(--priority-primary-80, #2d3fc0);
    }
  }
}

.fieldset {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 0;
  border: none;
}

.radioIcon {}
