@import '../../../../shared/styles/core.css';

.root {
  position: absolute;
  z-index: 50;
  display: flex;
  flex-direction: column;
  flex-wrap: initial;
  gap: 4px;
  align-items: flex-start;
  box-sizing: border-box;
  max-width: calc(100% - 16px);
  margin: 0;
  padding: 0;
  list-style: none;
}

.item {
  display: flex;
  justify-content: center;
  align-items: center;
}

.label {
  display: flex;
  gap: 2px;
  align-items: center;
  box-sizing: border-box;
  width: auto;
  min-width: 48px;
  height: 100%;
  min-height: 22px;
  margin: 0;
  padding: 0 6px;
  border-radius: 30px;
  letter-spacing: -0.12px;

  & .icon {
    width: 12px;
    height: 12px;
  }

  & :global {
    .tooltip__button {
      display: flex;
      gap: 4px;
      justify-content: center;
      align-items: center;
      width: auto;
      min-width: auto;
      height: 100%;
      min-height: initial;
      color: var(--default-grey-70, #717070);
    }
  }
}

.popoverPanel {
  width: 120px;
  padding: 8px;
  border-radius: 4px;
}

.topLeft {
  top: 8px;
  right: initial;
  bottom: initial;
  left: 8px;
}

.topRight {
  top: 8px;
  right: 8px;
  bottom: initial;
  left: initial;
  align-items: flex-end;
}

.bottomLeft {
  top: initial;
  right: initial;
  bottom: 8px;
  left: 8px;
}

.bottomRight {
  top: initial;
  right: 8px;
  bottom: 8px;
  left: initial;
  align-items: flex-end;
}

@media (--screen-xs) {
  .label {
    min-height: 23px;
  }

  .topLeft {
    top: 12px;
    left: 8px;
  }

  .topRight {
    top: 12px;
    right: 8px;
  }

  .bottomLeft {
    bottom: 12px;
    left: 8px;
  }

  .bottomRight {
    right: 8px;
    bottom: 12px;
  }
}

@media (--screen-md) {
  .label {
    padding: 0 12px;
  }
}

@media (--screen-lg) {
  .withTooltip {
    & :global {
      .tooltip__button {
        padding: 0 12px;
      }
    }
  }

  .root {
    gap: 8px;
    max-width: calc(100% - 24px);
  }

  .label {
    min-width: 44px;
    min-height: 25px;
    padding: 0 12px;

    & .icon {
      width: 16px;
      height: 16px;
    }
  }

  .topLeft {
    top: 16px;
    left: 12px;
  }

  .topRight {
    top: 16px;
    right: 12px;
  }

  .bottomLeft {
    bottom: 16px;
    left: 12px;
  }

  .bottomRight {
    right: 12px;
    bottom: 16px;
  }
}

@media (--screen-xl) {
  .topLeft {
    top: 20px;
    left: 12px;
  }

  .topRight {
    top: 20px;
    right: 12px;
  }

  .bottomLeft {
    bottom: 20px;
    left: 12px;
  }

  .bottomRight {
    right: 12px;
    bottom: 20px;
  }
}
