@import '../../../../shared/styles/core.css';

.root {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: flex-start;
  max-width: 681px;
}

.content {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: flex-start;
}

.block {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  margin-bottom: 32px;

  &:last-child {
    margin-bottom: 14px;
  }
}

.link {
  display: inline-block;
}

@media (--screen-lg) {
  .block {
    &:last-child {
      margin-bottom: 8px;
    }
  }
}

@media (--screen-xl) {
  .root {
    max-width: 1005px;
  }
}
