@import '../../../../styles/core.css';

.collectionItem {
  background: #fff;
  position: relative;
  display: block;
  padding-bottom: 64px;
  margin: 10px 10px 24px 10px;
  min-height: 360px;
  min-width: 320px;

  @media (--tablet) {
    margin: 0 9px !important;
  }

  &:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  }

  & .collectionItemImg {
    height: 164px;
    background-size: cover;
    background-position: center;
    border-radius: 4px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    @media (--tablet) {
      height: 138px;
    }

    & img {
      object-fit: cover;
      height: 164px;
      width: 100%;

      @media (--tablet) {
        height: 138px;
      }
    }
  }

  & .collectionItemTitle {
    margin: 18px 24px 0;
    font: 400 21px / 29px 'Playfair Display', serif;
    color: #49494D;
    text-align: center;

    @media (--tablet) {
      font-size: 22px;
      padding: 0 18px;
    }
  }

  & .collectionItemLink {
    position: absolute;
    bottom: 24px;
    text-align: center;
    width: 100%;
    color: #797980;
    font: 14px / 20px Manrope, sans-serif;
    letter-spacing: 1.4px;
    text-transform: uppercase;
  }
}
