@import '../../styles/core.css';

.root {
  all: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 6px;
  color: var(--priority-primary-80, #2d3fc0);
}
