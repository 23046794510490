@import '../../../shared/styles/core.css';

.root {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  justify-content: flex-start;
  align-items: center;
  margin: 0 auto;
  padding: 24px 0 60px;
  list-style: none;
}

.item {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 32px;
  min-height: 32px;
  padding: 0;
  border: none;
  color: var(--default-grey-70);
  text-decoration: none;
}

.item.active {
  background-color: var(--default-grey-20);
}

.control {
  border: 1px solid var(--default-grey-20);
  border-radius: var(--border-radius-default);
  color: var(--default-grey-70);
}

.label {
  font-weight: 400;

  @media (--s-md) {
    font-weight: 700;
  }
}

.jump {
  width: 100%;
  height: 100%;
  color: var(--default-grey-70);
  font-weight: 400;
  font-size: 14px;
  font-family: Manrope, sans-serif;
  line-height: 20px;
  letter-spacing: 0;
  text-align: left;
}

.disabled {
  border: none;
  color: var(--default-grey-70);
}

.prev {
  transform: rotate(180deg);
}

@media (--screen-sm) {
  .root {
    gap: 12px;
    padding: 24px 0 72px;
  }

  .item {
    min-width: 50px;
    min-height: 50px;
  }
}

@media (--screen-md) {
  .root {
    padding: 16px 0 72px;
  }
}

@media (--screen-lg) {
  .root {
    padding-top: 0;
  }
}
