@import '../../styles/core.css';

.root {
  position: relative;
  display: flex;
  gap: 4px;
  justify-content: center;
  align-items: center;
  overflow: visible;
  border-radius: 200px;
  background-color: var(--default-white-100, #ffffff);
  cursor: initial;
  user-select: none;

  & :global {
    .tooltip__button {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      color: var(--default-white-100);
    }
  }
}

.withTooltip {
  cursor: pointer;
}

.button {
  display: flex;
  margin: 0;
  padding: 0;
  border: none;
  background-color: transparent;
  color: var(--default-grey-40);
  outline: none;
}

.circle {
  padding: 0;
  border-radius: 50%;
  color: var(--default-white-100, #ffffff);

  & .button {
    padding: 0;
  }
}

.panel {
  position: absolute;
  top: unset;
  z-index: 100;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  box-sizing: border-box;
  width: 135px;
  padding: 8px;
  border-radius: var(--border-radius-default);
  background-color: var(--default-white-100);
  box-shadow: var(--shadow-lg);
  transform: translate(-50%, 100%);

  &::before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-top: 0;
    border-right: 6px solid transparent;
    border-bottom: 6px solid var(--default-white-100);
    border-left: 6px solid transparent;
  }
}

.top.right {
  right: calc(50% - 18px);
  bottom: 0;
  transform: translateY(calc(100% + 10px));

  &::before {
    top: 0;
    right: 12px;
    transform: translate(0, -93%);
  }
}

.top.left {
  bottom: 0;
  left: calc(50% - 18px);
  transform: translateY(calc(100% + 10px));

  &::before {
    top: 0;
    left: 12px;
    transform: translate(0, -93%);
  }
}

.bottom.right {
  top: 0;
  right: calc(50% - 18px);
  transform: translateY(calc(-100% - 10px));

  &::before {
    right: 12px;
    bottom: 0;
    transform: translate(0, 93%);
  }
}

.bottom.left {
  top: 0;
  left: calc(50% - 18px);
  transform: translateY(calc(-100% - 10px));

  &::before {
    bottom: 0;
    left: 12px;
    transform: translate(0, 93%);
  }
}
