@import '../../../../shared/styles/core.css';

.root {
  color: var(--default-grey-40);
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);

  & > .loader {
    position: absolute;
    z-index: 600;
    width: 33px;
    height: 33px;
  }
}
