@import '../../styles/core.css';

.root {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 12px;
}

.body {
  color: inherit;
}

.circle {
  min-width: 20px;
  min-height: 20px;
  padding: 0;
  border-radius: 50%;
  aspect-ratio: 1;
}

.primary {
  background-color: var(--priority-primary-80);
  color: var(--default-white-100);
}

.rectangle {
  display: flex;
  gap: 4px;
  justify-content: center;
  align-items: center;
  padding: 10px 12px;
  border-radius: 200px;
  background: var(--default-white-100, #ffffff);
}

.secondary {
  background-color: var(--default-grey-20);
  color: var(--default-grey-70);
}
