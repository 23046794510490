@import '../../../../shared/styles/core.css';

.root {
  & :global {
    & .modal__panel {
      position: relative;
      padding: 32px 16px;
      background-color: var(--default-grey-10);
    }
  }
}

.loader {
  position: fixed;
}

@media (--screen-xs) {
  .root {
    & :global {
      & .modal__panel {
        padding: 32px;
      }
    }
  }

  .loaderWrapper {
    max-width: calc(100vw - 128px);
    max-height: calc(100vh - env(safe-area-inset-bottom) - 112px);
  }
}

@media (--screen-lg) {
  .root {
    & :global {
      & .modal__panel {
        height: auto;
        padding: 40px;
      }
    }
  }
}

@media (--screen-xl) {
  .root {
    & :global {
      & .modal__panel {
        max-width: 1005px;
        padding: 48px 40px;
      }
    }
  }

  .loaderWrapper {
    max-height: calc(100vh - env(safe-area-inset-bottom) - 120px);
  }
}
