@import '../../../../shared/styles/core.css';

.root {
  & :global {
    .modal__panel {
      box-sizing: border-box;
      padding: 32px 0;
      background: var(--default-grey-10, #f6f6f6);
    }
  }
}

.container {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0 16px;
}

.title {
  margin-bottom: 16px;
}

.product {
  margin-bottom: 16px;
}

.banner {
  display: flex;
  width: 100%;
  margin-bottom: 24px;

  & :global {
    .picture__img {
      display: flex;
      object-fit: cover;
      width: 100%;
      min-height: 210px;
      aspect-ratio: 1.65;
    }
  }
}

.advantages {
  margin-bottom: 32px;
}

.content {
  margin-bottom: 32px;
}

.text {
  &:not(:last-of-type) {
    margin-bottom: 8px;
  }
}

.link {
  display: flex;
  gap: 4px;
  align-items: center;
}

@media (--screen-xs) {
  .banner {
    & :global {
      .picture__img {
        aspect-ratio: 2.07;
      }
    }
  }

  .container {
    padding: 0 32px;
  }
}

@media (--screen-lg) {
  .root {
    & :global {
      .modal__panel {
        display: grid;
        grid-template-areas:
          'banner title'
          'banner advantages'
          'banner advantages'
          'banner content'
          'banner link';
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-column-gap: 32px;
        box-sizing: border-box;
        max-width: 982px;
        padding: 40px;
      }
    }
  }

  .container {
    display: contents;
  }

  .banner {
    grid-area: banner;
    max-width: 435px;
    height: 100%;
    max-height: unset;
    margin: 0;
    aspect-ratio: unset;

    & :global {
      .picture__img {
        aspect-ratio: unset;
      }
    }
  }

  .title {
    grid-area: title;
    margin-bottom: 24px;
  }

  .product {
    display: none;
  }

  .advantages {
    grid-area: advantages;
    gap: 16px;
    height: fit-content;
    margin-bottom: 24px;
  }

  .content {
    grid-area: content;
    margin-bottom: 24px;
  }

  .text {
    &:not(:last-of-type) {
      margin-bottom: 16px;
    }
  }

  .link {
    grid-area: link;
  }
}

@media (--screen-xl) {
  .root {
    & :global {
      .modal__panel {
        grid-template-columns: minmax(0, 1fr) 434px;
        grid-column-gap: 40px;
        width: 1005px;
        max-width: unset;
        padding: 48px 40px;
      }
    }
  }

  .banner {
    display: flex;
    width: 443px;
    max-width: unset;
  }

  .title {
    margin-bottom: 32px;
  }

  .advantages {
    margin-bottom: 32px;
  }

  .content {
    margin-bottom: 32px;
  }
}
