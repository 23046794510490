@import '../../../../shared/styles/core.css';


.loader {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.root {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  padding: 0 16px;

  @media (--s-md) {
    padding: 0 32px;
  }

  @media (--s-xl) {
    padding: 0 48px;
  }

  @media (--s-3xl) {
    padding: 0 200px;

  }
}

.contentLimiter {
  margin: 0;
  gap: 4px;
}

.contentLimiterControl {
  font-weight: 700;
}

.section {
  &.main {
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-start;
    box-sizing: border-box;
    padding: 0 0 80px;
    width: 100%;
  }

  .sidebar {
    display: none;
    flex-shrink: 0;
    width: 100%;

    @media (--s-xl) {
      display: block;
      width: 278px;
      padding: 0 38px 0 0;
    }

    @media (--s-3xl) {
      width: 362px;
      padding: 0 62px 0 0;
    }
  }

  .content {
    width: 100%;

    @media (--s-xl) {
      padding: 0 0 0 24px;
    }

    @media (--s-3xl) {
      padding: 0 0 0 24px;
    }
  }
}

.navigationToolbar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 24px 0 8px;

  @media (--s-md) {
    padding: 40px 0 8px;
  }

  @media (--s-xl) {
    padding: 48px 0 8px;
  }

  @media (--s-2xl) {
    flex-direction: row;
  }

  .loadMore {
    white-space: nowrap;
    min-height: 48px;

    @media (--s-xl) {
      min-height: 48px;
    }

    @media (--s-3xl) {
      min-height: 48px;
    }
  }

  .pagination {
    padding: 24px 0 0;

    @media (--s-xl) {
      margin: 0;
    }

    @media (--s-2xl) {
      margin: 0;
      padding: 0;
    }
  }
}

.footer {
  padding: 24px 0 0;

  @media (--s-md) {
    padding: 32px 0 0;
  }

  @media (--s-xl) {
    padding: 40px 0 0;
  }

  @media (--s-3xl) {
    padding: 56px 0 0;
  }

  .text {
    margin: 16px 0 0;
  }

  & a {
    text-decoration: none;
  }

  .title {
    margin: 24px 0 0;
    font-weight: 500;
    font-size: 22px;
    line-height: 28px;

    @media (--s-md) {
      font-size: 22px;
      line-height: 30px;
    }

    @media (--s-xl) {
      font-size: 24px;
      line-height: 32px;
    }

    @media (--s-3xl) {
      font-size: 26px;
      line-height: 32px;
    }
  }
}

.link {
  display: inline-block;
}
