@import '../../styles/core.css';

.icon {
  display: flex;
  width: 16px;
  height: 16px;
}

.limiter {
  overflow: hidden;
}

.content {
  position: relative;
  display: grid;
  width: 100%;
  transition: all var(--transition-md);

  &::after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    display: inline-block;
    width: 100%;
    height: 55px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 92%);
  }
}

.root {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
  width: 100%;

  &.opened {
    & .content {
      &::after {
        display: none;
      }
    }

    & .icon {
      transform: rotate(180deg);
    }
  }
}

.control {
  gap: 4px;
  justify-content: flex-start;
  padding: 2px;
  color: var(--priority-primary-80);

  &:hover,
  &:focus-visible {
    color: var(--priority-primary-100);
  }
}

@media (--screen-lg) {
  .root {
    gap: 12px;
  }
}
