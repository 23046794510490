@import '../../../../../shared/styles/core.css';

.root {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  width: 100%;

  & :global {
    .slider-list {
      width: calc(100% + 24px);
      margin: 0 -12px;
    }
  }
}

.result {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  gap: 12px;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  padding-bottom: 40px;
}

.content {
  color: var(--default-grey-70);
  text-align: center;
}

.title {
  text-align: center;
}

.subtitle {
  text-align: center;
}

.popular {
  width: 100%;
  padding: 40px 0;
}

.popularLoader {
  grid-template-columns: repeat(2, minmax(0, 1fr));

  & :global {
    .model-card__loader-item {
      max-height: 278px;
    }
  }
}

@media (--s-xs) {
  .result {
    gap: 16px;
    padding-bottom: 32px;
  }

  .popular {
    padding: 32px 0;
  }
}

@media (--s-md) {
  .popular {
    padding: 32px 0;
  }

  .popularLoader {
    grid-template-columns: repeat(4, minmax(0, 1fr));

    & :global {
      .model-card__loader-item {
        max-height: 317px;
      }
    }
  }

  .backButton {
    width: auto;
    min-width: 124px;
  }
}

@media (--s-lg) {
  .result {
    padding-top: 33px;
    padding-bottom: 48px;
  }

  .popular {
    padding: 48px 0 4px;
  }
}

@media (--s-xl) {
  .result {
    padding-top: 41px;
    padding-bottom: 56px;
  }

  .popular {
    padding: 56px 0 4px;
  }

  .popularLoader {
    grid-template-columns: repeat(4, minmax(0, 1fr));

    & :global {
      .model-card__loader-item {
        max-height: 356px;
      }
    }
  }
}
