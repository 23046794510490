@import '../../styles/core.css';

.simpleGallery {
  display: flex;
  justify-content: space-between;
  padding: 16px 0;
}

.arrows {
  position: absolute;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.arrowButton {
  pointer-events: all;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border: 0 solid transparent;
  border-radius: 0;
  background: rgba(248, 248, 248, 0.5);
  outline: none;
  cursor: pointer;

  @media (--tablet) {
    width: 32px;
    height: 32px;

    & img {
      width: 12px;
      height: 12px;
    }

    & svg {
      height: 12px;
    }
  }

  &.disabled {
    visibility: hidden;
  }

  &.shadowed {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  }

  &.new {
    box-shadow: none;

    @media (--tablet) {
      width: 32px;
      height: 32px;

      & img {
        width: 32px;
        height: 32px;
      }

      & svg {
        height: 32px;
      }
    }
  }
}

.container {
  width: 100%;
  height: 100%;

  & li {
    outline: none;
  }

  & .points {
    display: flex;
    align-items: center;
    padding: 0 40px 20px 0;

    @media (--tablet) {
      padding: 0 20px 20px 0;
    }

    & .point {
      position: relative;
      flex-shrink: 0;
      width: 16px;
      height: 16px;
      border: 1px solid var(--colorWhite);
      border-radius: 50%;
      background: transparent;
      outline: none;
      cursor: pointer;

      @media (--tablet) {
        width: 12px;
        height: 4px;
        border-color: var(--colorBorderDefault);
        border-radius: 2px;
      }

      &:hover {
        background-color: var(--colorBorderDefault);
      }

      & + .point {
        margin-left: 8px;

        @media (--tablet) {
          margin-left: 4px;
        }
      }

      &.selected {
        border: 1px solid var(--colorPrimary);
        background-color: var(--colorPrimary);

        &::after {
          content: '';
          position: absolute;
          top: 4px;
          left: 4px;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background-color: var(--colorWhite);

          @media (--tablet) {
            display: none;
          }
        }

        &:hover {
          background-color: var(--colorBorderDefault);

          &::after {
            background-color: transparent;
          }
        }
      }
    }
  }

  &.paddedBottom {
    padding-bottom: 20px;

    & .points {
      padding: 0 40px 40px 0;
    }
  }

  & :global(.slider-control-bottomright) {
    & .points {
      display: flex;

      @media (--tablet) {
        display: none;
      }
    }
  }

  & :global(.slider-control-bottomcenter) {
    & .points {
      display: none;
      width: 100%;
      padding: 8px 16px;

      @media (--tablet) {
        display: flex;
      }
    }
  }

  &:global(.slider-slide) {
    outline: none;
  }
}

.container :global(.slider-list .slider-slide:not(:first-child)) {
  display: none !important;
}

.container.loaded :global(.slider-list .slider-slide:not(:first-child)) {
  display: inline-block !important;
}

.container :global(.slider-control-centerleft),
.container :global(.slider-control-centerright) {
  transform: translateY(-50%);
}
